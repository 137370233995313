'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _plugin = require('@swup/plugin');

var _plugin2 = _interopRequireDefault(_plugin);

var _delegate = require('delegate');

var _delegate2 = _interopRequireDefault(_delegate);

var _utils = require('swup/lib/utils');

var _helpers = require('swup/lib/helpers');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var PreloadPlugin = function (_Plugin) {
    _inherits(PreloadPlugin, _Plugin);

    function PreloadPlugin() {
        var _ref;

        var _temp, _this, _ret;

        _classCallCheck(this, PreloadPlugin);

        for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
            args[_key] = arguments[_key];
        }

        return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = PreloadPlugin.__proto__ || Object.getPrototypeOf(PreloadPlugin)).call.apply(_ref, [this].concat(args))), _this), _this.name = "PreloadPlugin", _this.onContentReplaced = function () {
            _this.swup.preloadPages();
        }, _this.onMouseover = function (event) {
            var swup = _this.swup;

            swup.triggerEvent('hoverLink', event);

            var link = new _helpers.Link(event.delegateTarget);
            if (link.getAddress() !== (0, _helpers.getCurrentUrl)() && !swup.cache.exists(link.getAddress()) && swup.preloadPromise == null) {
                swup.preloadPromise = swup.preloadPage(link.getAddress());
                swup.preloadPromise.route = link.getAddress();
                swup.preloadPromise.finally(function () {
                    swup.preloadPromise = null;
                });
            }
        }, _this.preloadPage = function (pathname) {
            var swup = _this.swup;

            var link = new _helpers.Link(pathname);
            return new Promise(function (resolve, reject) {
                if (link.getAddress() != (0, _helpers.getCurrentUrl)() && !swup.cache.exists(link.getAddress())) {
                    (0, _helpers.fetch)({ url: link.getAddress(), headers: swup.options.requestHeaders }, function (response) {
                        if (response.status === 500) {
                            swup.triggerEvent('serverError');
                            reject();
                        } else {
                            // get json data
                            var page = swup.getPageData(response);
                            if (page != null) {
                                page.url = link.getAddress();
                                swup.cache.cacheUrl(page, swup.options.debugMode);
                                swup.triggerEvent('pagePreloaded');
                            } else {
                                reject(link.getAddress());
                                return;
                            }
                            resolve(swup.cache.getPage(link.getAddress()));
                        }
                    });
                } else {
                    resolve(swup.cache.getPage(link.getAddress()));
                }
            });
        }, _this.preloadPages = function () {
            (0, _utils.queryAll)('[data-swup-preload]').forEach(function (element) {
                _this.swup.preloadPage(element.href);
            });
        }, _temp), _possibleConstructorReturn(_this, _ret);
    }

    _createClass(PreloadPlugin, [{
        key: 'mount',
        value: function mount() {
            var swup = this.swup;

            swup._handlers.pagePreloaded = [];
            swup._handlers.hoverLink = [];

            swup.preloadPage = this.preloadPage;
            swup.preloadPages = this.preloadPages;

            // register mouseover handler
            swup.delegatedListeners.mouseover = (0, _delegate2.default)(document.body, swup.options.linkSelector, 'mouseover', this.onMouseover.bind(this));

            // initial preload of page form links with [data-swup-preload]
            swup.preloadPages();

            // do the same on every content replace
            swup.on('contentReplaced', this.onContentReplaced);
        }
    }, {
        key: 'unmount',
        value: function unmount() {
            var swup = this.swup;

            swup._handlers.pagePreloaded = null;
            swup._handlers.hoverLink = null;

            swup.preloadPage = null;
            swup.preloadPages = null;

            swup.delegatedListeners.mouseover.destroy();

            swup.off('contentReplaced', this.onContentReplaced);
        }
    }]);

    return PreloadPlugin;
}(_plugin2.default);

exports.default = PreloadPlugin;